export const sortingMethods = [
  // {
  //   name: 'popular',
  //   icon: 'star',
  // },
  // {
  //   name: 'trending',
  //   icon: 'graph-up',
  // },
  {
    name: 'earliest',
    icon: 'sort-numeric-up',
  },
  {
    name: 'latest',
    icon: 'sort-numeric-down',
  },
];
