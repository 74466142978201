/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type { DappsFacet, DappsFacetInterface } from "../DappsFacet";

const _abi = [
  {
    inputs: [],
    name: "ERC20Base__ApproveFromZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "ERC20Base__ApproveToZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "ERC20Base__BurnExceedsBalance",
    type: "error",
  },
  {
    inputs: [],
    name: "ERC20Base__BurnFromZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "ERC20Base__InsufficientAllowance",
    type: "error",
  },
  {
    inputs: [],
    name: "ERC20Base__MintToZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "ERC20Base__TransferExceedsBalance",
    type: "error",
  },
  {
    inputs: [],
    name: "ERC20Base__TransferFromZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "ERC20Base__TransferToZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "Factory__FailedDeployment",
    type: "error",
  },
  {
    inputs: [],
    name: "Ownable__NotOwner",
    type: "error",
  },
  {
    inputs: [],
    name: "Ownable__NotTransitiveOwner",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "previousAdminRole",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "newAdminRole",
        type: "bytes32",
      },
    ],
    name: "RoleAdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleGranted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleRevoked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "_baseplateId",
        type: "bytes32",
      },
    ],
    name: "createClient",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_baseplate",
        type: "string",
      },
    ],
    name: "getBaseplate",
    outputs: [
      {
        internalType: "address",
        name: "baseplate",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "_baseplate",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "_addr",
        type: "address",
      },
    ],
    name: "setBaseplate",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class DappsFacet__factory {
  static readonly abi = _abi;
  static createInterface(): DappsFacetInterface {
    return new utils.Interface(_abi) as DappsFacetInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): DappsFacet {
    return new Contract(address, _abi, signerOrProvider) as DappsFacet;
  }
}
